import { v4 as uuidv4 } from 'uuid'
import { ACCION_EJEC } from '@/utils/consts'

export default {
  async selectParteTrabajoMatSist (Vue, idparteTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.tsubfamilia,
        tables.subfamilia.idtsubfamilia.eq(tables.tsubfamilia.idtsubfamilia)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist)
        )
      )
      .exec())[0]
  },
  async insert (Vue, formData, idordenTrabajoMatsist, idparteTrabajo) {
    await Vue.$offline.accionEjec.insertSync({
      idaccion_ejec: uuidv4(),
      idorden_trabajo_matsist: idordenTrabajoMatsist,
      idmaccion: formData.idmaccion,
      idest_accion_ejec: ACCION_EJEC.estados.pendiente,
      facturable: formData.facturable,
      idparte_trabajo: idparteTrabajo,
      observaciones: formData.observaciones,
    })
  },
}
